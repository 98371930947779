/**
 ** Copyright (C) 2017 Digital Cognition Technologies.  All Rights Reserved.
 ** Unauthorized copying of this file via any medium is strictly prohibited
 ** without the express permission of Digital Cognition Technologies.
 ** Proprietary and confidential.
 **/
/*##############################################################################
Player drawing canvas
##############################################################################*/
.font_xxxs {
  font-size: 10px;
  line-height: 12px;
}
.disabled_color {
  color: #9e9e9e;
}
.dct_player_drawstack {
  overflow: hidden;
  position: relative;
}
.drawstack_split {
  display: flex;
}
.drawstack_split > * {
  flex: 1;
}
.drawstack_split > *:first-child {
  margin-right: 8px;
}
.drawstack_paper {
  position: absolute;
  box-sizing: border-box;
}
@media print {
  .drawstack_paper {
    border: 1px solid #000000;
  }
}
@media screen {
  .drawstack_paper {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
  }
}
.dct_player_drawstack.drawstack_zoomed .drawstack_paper {
  display: none;
}
.drawstack_background {
  display: none;
  /* Auto-changed to block on successful load */
  margin-left: -1px;
  /* The image is the exact same width/height as the outer width/height of the parent. */
  margin-top: -1px;
  /* However, that has a border, and this doesn't.  So offset to account for border. */
  opacity: 0.6;
  width: inherit;
  height: inherit;
}
.drawstack_canvas {
  position: absolute;
  transform-origin: top left;
  transition: transform 128ms ease 0ms;
  /*
    On-screen, we use "transform" to do this.
    In print, we use "left" and "top" to do this.
    This works around a bug in MS Edge where printing does not position correctly for transform, but does for left/top.
  */
}
@media print {
  .drawstack_canvas {
    transform: none !important;
  }
}
@media screen {
  .drawstack_canvas {
    left: 0px !important;
    top: 0px !important;
  }
}
.drawstack_canvas.instant_move {
  transition: transform 0ms linear 0ms;
}
.dct_player.dct_player_zoomable .dct_player_drawstack {
  cursor: zoom-in;
}
.dct_player.dct_player_zoomable .dct_player_drawstack.drawstack_zoommax {
  cursor: zoom-out;
}
.dct_player.dct_player_stretch {
  width: 100%;
  text-align: center;
}
.dct_player.dct_player_stretch > * {
  text-align: left;
}
.dct_player.dct_player_stretch .dct_player_drawstack {
  display: inline-block;
}
.dct_player {
  transition: opacity 128ms linear 0ms;
}
.form_loading .dct_player {
  opacity: 0.38;
}
/*##############################################################################
Player drawing columns
##############################################################################*/
.player_draw_row {
  display: flex;
}
.player_draw_row > * + * {
  margin-left: 16px;
}
/*##############################################################################
Annotations
##############################################################################*/
.drawstack_annotation {
  font-size: 10px;
  line-height: 12px;
  line-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #4d4d4d;
  padding: 2px;
  position: absolute;
  width: auto;
  transition: opacity 128ms linear 0ms;
  user-select: none;
  opacity: 0;
}
@media print {
  .drawstack_annotation {
    display: none;
  }
}
.drawstack_annotation.active {
  opacity: 1;
}
.drawstack_annotation > * {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
}
.drawstack_nib {
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  border-radius: 50px;
  background: rgba(134, 198, 90, 0.5);
  position: absolute;
  display: none;
}
.drawstack_show_nib .drawstack_nib {
  display: inline-block;
}
@media print {
  .drawstack_show_nib .drawstack_nib {
    display: none;
  }
}
/*##############################################################################
Annotations
##############################################################################*/
.dct_player_splitinfo {
  text-align: center;
  color: #9e9e9e;
}
